<template>
    <div class="task">
        <Loading v-if="loading"></Loading>
        <AddAccount :visible="addShow" :editData="eidtData" @cancel="(value)=>{addShow=value}" @submit="GetAllUsers" v-if="addShow"></AddAccount>
        <div class="clearfix">
            <span class="title csP" @click="$router.push('/home/index')"><a-icon type="left" style="color:#6a87e6;margin-right:10px"/>首页</span>
            <a-button class="btn fR"  @click="addShow=true;eidtData = {}"><a-icon type="plus"/>添加账号</a-button>
            <a-button class="btn border fR mr20px" @click="delShow=!delShow">管理账号</a-button>
        </div>
        <ul class="taskList" v-if="list.length">
            <li v-for="(item,index) in list" :key="index">
                <transition name="fade">
                    <div class="dele" v-if="delShow" @click="deleteAccount(item)" :class="{iconDisable:item.id==1}"><a-icon type="minus"/></div>
                </transition>
                <div class="topInfo">
                    <div class="headImg"><img :src="item.headImg" alt=""></div>
                    <div style="position:relative">
                        <p class="name" :title="item.nickname">{{item.nickname}}</p>
                        <button class="sBtn" @click="copyAccount(item, $event)">复制账号密码</button>
                        <icon-font type="icon-bianji" title="编辑" @click="()=>{addShow=true;eidtData = item}" class="editIcon"/>
                    </div>
                </div>
                <div>
                    <p><span class="point"></span>总接收任务:<span class="mainColor">40</span></p>
                    <p><span class="point"></span>已完成:<span class="mainColor">40</span></p>
                    <p><span class="point"></span>待完成:<span class="mainColor">40</span></p>
                </div>
            </li>
        </ul>
        <a-pagination v-model="current" :total="total" v-if="total>0"/>
    </div>
</template>
<script>
import AddAccount from '@/components/addAccount'
import Loading from '@/components/loading'
import { getAllUsers, delUser } from '@/libs/api'
import { IconFont } from '@/libs/IconFont'

export default {
    name:'task',
    components:{ AddAccount, Loading, IconFont },
    data(){
        return{
            current:1,
            delShow:false,
            addShow:false,
            loading:true,
            list:[],
            total: 0,
            spinning:true,
            eidtData:{},
            pagination:{
                page:1,
                size:8
            }
        }
    },
    mounted(){
        this.GetAllUsers()
    },
    methods:{
        GetAllUsers(){
            getAllUsers({...this.pagination}).then(res=>{
                this.loading=false
                if(res){
                    let {count,list}=res
                    this.list=list
                    this.total=count
                }
            },error=>{
                this.loading=false
            })
        },
        deleteAccount({id}){
            if(id == 1) return
            delUser({id}).then(res=>{
                this.GetAllUsers()
            },error=>{
                console.log(error)
            })
        },
        EditUser(item){
            this.eidtData = item
        },
        copyAccount(item, e){
            e.stopPropagation()
            this.copyData = `账号:${item.username}`
            this.$copyText(this.copyData).then(res=> {
                this.$message.success('复制成功')
            },error=>{
                this.$message.success('复制成功')
                console.log(error)
            })
        },
    }
}
</script>
<style lang="less" scoped>
.dele{
    width: 30px;
    height: 30px;
    background: #ff5863;
    border-radius: 100%;
    position:absolute;
    top: -25px;
    right: -15px;
    z-index: 99;
    text-align: center;
    font-size: 20px;
    color: #fff;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.iconDisable{
    background-color: #ccc;
    cursor: no-drop
}
.editIcon{
    position: absolute;
    font-size: 18px;
    color: #5180e0;
    right:-18px;
    top: 6px
}
</style>